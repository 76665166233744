export default {
  props: {
    cachePrefix: { type: String, required: true },
    cacheKey: { type: String, required: true },
    cacheType: { type: String, required: false },
  },
  serverCacheKey({ cachePrefix, cacheKey, cacheType }) {
    /**
     * https://codesandbox.io/s/github/nuxt/nuxt.js/tree/dev/examples/cached-components?from-embed=&file=/pages/index.vue
     * https://codesandbox.io/s/m3x2jvo908?file=/components/item.vue
     *
     * 10000 => 10s
     * 100000 => 100s         =>    1,67m =>  0.03h
     * 1000000 => 1,000s      =>   16,67m =>  0.28h
     * 10000000 => 10,000s    =>  166,67m =>  2.78h
     * 100000000 => 100,000s  => 1666,67m => 27.78h
     */
    const divisor =
      process.env.NODE_ENV === 'production'
        ? typeof cacheType === 'undefined' || cacheType === 'dynamic'
          ? 10000000
          : 100000000
        : 10000

    const timer = Math.floor(Date.now() / divisor)

    return `${cachePrefix}-${cacheKey}-${timer}`
  },
}
