<template>
  <div class="home-wrapper--blog">
    <el-container class="wrapper-blog">
      <el-main>
        <div class="container-blog">
          <div class="wrapper-section-header">
            <h2 class="home-section-title">{{ $t(`${base}.title`) }}</h2>
            <p class="home-section-description">{{ $t(`${base}.subtitle`) }}</p>
          </div>

          <LazyLoading
            :key="`blog`"
            :loading="posts.length === 0"
            :loaded="lazy.blog"
            @loaded="(e) => (lazy.blog = e)"
          >
            <Swiper v-if="lazy.blog" ref="swiperBlog" :options="swiperOption">
              <SwiperSlide v-for="(post, i) in posts" :key="i">
                <LadCard
                  :item="post"
                  :base="base"
                  :link="post.permalink"
                  target="_blank"
                />
              </SwiperSlide>

              <div slot="button-prev" class="swiper-button-prev"></div>
              <div slot="button-next" class="swiper-button-next"></div>
            </Swiper>

            <template slot="css-skeleton">
              <LadBlogSwiperLoader />
            </template>
          </LazyLoading>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import CacheComponentMixin from '@/utils/mixin/cache-components'
import { uuidv4, toText } from '@/utils'

export default {
  name: 'LadHomeBlog',
  components: {
    LadCard: () =>
      import(/* webpackChunkName: "lad-card" */ '@/components/card/index'),
    LadBlogSwiperLoader: () =>
      import(
        /* webpackChunkName: "lad-blog-swiper-loader" */ '@/components/css-skeleton/blog-swiper-loader'
      ),
  },
  mixins: [CacheComponentMixin],
  data() {
    return {
      base: 'home.blog',
      posts: [],
      medias: [],
      lazy: { blog: false },
      current: 0,
      swiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 30,
        // Responsive breakpoints
        breakpoints: {
          // when window width is >= 480px
          480: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          // when window width is >= 800px
          800: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        on: {
          slideChange: () => {
            const swiper = this.$refs.swiperBlog.$swiper

            if (!!swiper && swiper.activeIndex && !isNaN(swiper.activeIndex)) {
              this.current = swiper.activeIndex % this.posts.length
            }
          },
        },
      },
    }
  },
  watch: {
    'lazy.blog': {
      immediate: true,
      handler(newValue, oldValue) {
        if (process.server) return

        if (newValue) {
          this.getData()
        }
      },
    },
  },
  methods: {
    async getData() {
      const limit = 5
      const url = `${process.env.APP_URL_BLOG}/wp-json/wp/v2/posts?per_page=${limit}&filter[orderby]=date&order=desc&status=publish&_embed`

      try {
        const response = await this.$axios.get(url)

        response.forEach((post) => {
          const description =
            toText(post.excerpt.rendered).split(' ').slice(0, 20).join(' ') +
            '...'

          const url = new URL(post.link)

          const image = post._embedded['wp:featuredmedia'][0]
          this.posts.push({
            id: post.id,
            type: 'post',
            title: post.title.rendered,
            description,
            date: this.$dayjs(post.date).format('YYYY-MM-DD'),
            permalink: `${process.env.APP_URL}/blog${url.pathname}`,
            media: {
              type: 'image',
              data: {
                srcset: '',
                url: image.media_details.sizes['contentberg-list-b'].source_url,
                uuid: uuidv4(),
              },
            },
          })
        })
      } catch (error) {}
    },
  },
}
</script>

<style lang="scss">
.home-wrapper--blog {
  background-color: #ebeef2;
  padding-bottom: 3em;

  .swiper-pagination {
    position: relative;
    margin-top: 20px;
    bottom: inherit;
  }

  h3.title {
    line-height: 1.4;
    height: 40px;
    margin: 0;
    font-size: 14px;
  }

  @media screen and (max-width: 480px) {
    .swiper-wrapper {
      .swiper-slide {
        width: calc(100% - 100px);
        min-width: 220px;
      }
    }
  }
}
</style>
